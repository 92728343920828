import { Fragment } from 'react';
import parse from 'html-react-parser';

import styles from './Blocks.module.scss';

import BlockAccordion from '@/blocks/BlockAccordion/BlockAccordion';
import BlockButton from '@/blocks/BlockButton/BlockButton';
import BlockButtons from '@/blocks/BlockButtons/BlockButtons';
import BlockCarousel from '@/blocks/BlockCarousel/BlockCarousel';
import BlockColumns from '@/blocks/BlockColumns/BlockColumns';
import BlockCover from '@/blocks/BlockCover/BlockCover';
import BlockEmbed from '@/blocks/BlockEmbed/BlockEmbed';
import BlockGallery from '@/blocks/BlockGallery/BlockGallery';
import BlockGroup from '@/blocks/BlockGroup/BlockGroup';
import BlockHeading from '@/blocks/BlockHeading/BlockHeading';
import BlockImage from '@/blocks/BlockImage/BlockImage';
import BlockImageWithLogo from '@/blocks/BlockImageWithLogo/BlockImageWithLogo';
import BlockList from '@/blocks/BlockList/BlockList';
import BlockMediaText from '@/blocks/BlockMediaText/BlockMediaText';
import BlockParagraph from '@/blocks/BlockParagraph/BlockParagraph';
import BlockQueryHandler from '@/blocks/BlockQueryHandler/BlockQueryHandler';
import BlockQuote from '@/blocks/BlockQuote/BlockQuote';
import BlockRotateText from '@/blocks/BlockRotateText/BlockRotateText';
import Blocks from '@/blocks/Blocks/Blocks';
import BlockSeparator from '@/blocks/BlockSeparator/BlockSeparator';
import BlockSpacer from '@/blocks/BlockSpacer/BlockSpacer';
import AudioPlayer from '@/components/AudioPlayer/AudioPlayer';
import FreeForm from '@/components/FreeForm/FreeForm';
import Newsletter from '@/components/Newsletter/Newsletter';
import SocialShare from '@/components/SocialShare/SocialShare';

import sanitizer from '@/utils/sanitizer';

import { Block } from '@/client';

let key = 0;

function displayBlock(block: Block) {
  // @ts-ignore
  const { name, attributes, innerBlocks, dynamicContent, saveContent } = block;
  key++;

  switch (name) {
    // Core WordPress blocks
    case 'core/group':
      return <BlockGroup {...attributes} innerBlocks={innerBlocks} key={key} />;
    case 'core/heading':
      return <BlockHeading {...attributes} content={saveContent} key={key} />;
    case 'core/paragraph':
      return <BlockParagraph {...attributes} content={saveContent} key={key} />;
    case 'core/query':
      return <BlockQueryHandler {...attributes} innerBlocks={innerBlocks} key={key} />;
    case 'core/columns':
      return <BlockColumns {...attributes} innerBlocks={innerBlocks} key={key} />;
    case 'core/button':
      return <BlockButton {...attributes} content={saveContent} key={key} />;
    case 'core/buttons':
      return <BlockButtons {...attributes} innerBlocks={innerBlocks} key={key} />;
    case 'core/image':
      return <BlockImage {...attributes} content={saveContent} key={key} />;
    case 'core/list':
      return <BlockList {...attributes} key={key} innerBlocks={innerBlocks} />;
    case 'core/quote':
      return <BlockQuote {...attributes} key={key} innerBlocks={innerBlocks} />;
    case 'core/separator':
      return <BlockSeparator {...attributes} content={saveContent} key={key} />;
    case 'core/cover':
      return <BlockCover {...attributes} content={saveContent} key={key} />;
    case 'core/media-text':
      return <BlockMediaText {...attributes} key={key} innerBlocks={innerBlocks} />;
    case 'core/spacer':
      return <BlockSpacer {...attributes} content={saveContent} key={key} />;
    case 'core/embed':
      return <BlockEmbed {...attributes} content={saveContent} key={key} />;
    case 'core/post-title': {
      const sanitizedHtml = dynamicContent ? sanitizer(dynamicContent) : '';
      return <Fragment key={key}>{parse(sanitizedHtml)}</Fragment>;
    }
    case 'core/block':
      return <Blocks blocks={innerBlocks ?? []} key={key} />;
    case 'core/freeform':
      return <FreeForm content={attributes.content ?? ''} key={key} />;
    //  Custom blocks
    case 'jam3/company-image-with-logo':
      return <BlockImageWithLogo {...attributes} innerBlocks={innerBlocks} key={key} />;
    case 'jam3/rotate-text':
      return <BlockRotateText {...attributes} innerBlocks={innerBlocks} key={key} />;
    case 'jam3/carousel':
      return <BlockCarousel {...attributes} innerBlocks={innerBlocks} key={key} />;
    case 'jam3/accordion':
      return <BlockAccordion {...attributes} innerBlocks={innerBlocks} key={key} />;
    case 'jam3/newsletter':
      return <Newsletter key={key} />;
    case 'core/gallery':
      return <BlockGallery {...attributes} innerBlocks={innerBlocks} key={key} />;
    case 'core/table':
      const sanitizedHTML = saveContent ? sanitizer(saveContent) : '';
      return <Fragment key={key}>{parse(sanitizedHTML)}</Fragment>;
    case 'core/audio':
      return <AudioPlayer {...attributes} content={saveContent} key={key} />;
    case 'jam3/social-share':
      return <SocialShare key={key} />;
    // eslint-disable-next-line sonarjs/no-duplicated-branches
    case 'jam3/company-info': {
      const sanitizedHTML = saveContent ? sanitizer(saveContent) : '';
      return <Fragment key={key}>{parse(sanitizedHTML)}</Fragment>;
    }
    case 'acf/jam3-acf-investments': {
      const sanitizedHTML = dynamicContent ? sanitizer(dynamicContent) : '';
      return <Fragment key={key}>{parse(sanitizedHTML)}</Fragment>;
    }
    default:
      return (
        <div className={styles.DefaultBlock} key={key}>
          <p>
            Block <span>{name}</span> not supported
          </p>
          {attributes && <pre>{JSON.stringify(attributes, null, 2)}</pre>}
        </div>
      );
  }
}

export default displayBlock;
